module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hello! I'm Douglas","short_name":"Hello! I'm Douglas","start_url":"/","background_color":"#ffffff","theme_color":"#25303B","display":"minimal-ui","icon":"static/images/avatar.jpeg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166903031-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
